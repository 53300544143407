import React from "react"

const CheckIcon = ({
  width = "16",
  height = "13",
}: {
  width?: string
  height?: string
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.38162 10.1739L1.74436 6.41208L0.510132 7.68857L5.38162 12.7269L15.8486 1.90149L14.6143 0.625L5.38162 10.1739Z"
      fill="#30AB76"
    />
  </svg>
)

export default CheckIcon
