import React from "react"
import styled from "@emotion/styled"

import { useQueryString } from "@capitaria/capitaria-utms"
import { trackEvent } from "../../helpers/tracker"
import Button from "../ui/button"

const HeaderContainer = styled.div`
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000000;
  background-image: url("/extend/mobile.jpg");
  background-size: cover;
  min-height: 124px;
  padding-bottom: 30px;
  width: 100%;
  min-height: 80vh;

  @media (min-width: 460px) {
    min-height: 360px;
  }

  @media (min-width: 736px) {
    padding-top: 60px;
    min-height: 335px;
    background-image: url("/extend/tablet.jpg");
  }

  @media (min-width: 1024px) {
    padding-top: 90px;
    background-image: url("/extend/desktop.jpg");
    padding-bottom: 40px;
  }

  @media (min-width: 1920px) {
    background-image: url("/extend/widescreen.jpg");
  }
`

const Title = styled.div`
  font-weight: bold;
  font-size: 23.04px;
  line-height: 140%;
  color: #ffffff;
  margin-bottom: 15px;
`

const Description = styled.div`
  font-size: 14px;
  line-height: 140%;
  color: #ffffff;
  margin: 0 40px;
  text-align: center;
  margin-bottom: 40px;
  max-width: 350px;
  @media (min-width: 736px) {
    max-width: 443px;
    margin-bottom: 27px;
  }
  @media (min-width: 1024px) {
    max-width: 460px;
  }
`

const Header = () => {
  const {
    queryString,
    parsedQueryString: { mc_origen: mcOrigen },
  } = useQueryString()

  return (
    <HeaderContainer>
      <Title>Somos Capitaria</Title>
      <Description>
        Mucha gente no sabe dónde o cómo invertir su dinero. En Capitaria te
        damos acceso online a los mercados financieros y te acompañamos
        identificando oportunidades, para que así, sin ser un experto, tomes el
        control de tu capital de inversión
      </Description>
      <Button
        href={`https://onboarding.capitaria.com/sign-up${queryString}`}
        onClick={() => {
          trackEvent("acerca-de-nosotros:header:create-account", {
            mc_origen: mcOrigen,
          })
        }}
      >
        CREA TU CUENTA
      </Button>
    </HeaderContainer>
  )
}

export default Header
