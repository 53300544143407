import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import CustomSlider from "../ui/custom-slider"

type FounderType = {
  image: string
  name: string
  description: string
  linkedin?: string
}

type Props = {
  data: FounderType
}

const founders: FounderType[] = [
  {
    image: "/founders/andres-rojas.png",
    name: "Andrés Rojas",
    description:
      "Ingeniero Civil Industrial de la Universidad Adolfo Ibáñez. Más de 20 años de experiencia en el mercado financiero",
  },
  {
    image: "/founders/erwin-andia.png",
    name: "Erwin Andia",
    description:
      "Master en Marketing y Dirección Comercial de la UAI y Master of Science en Liderazgo y Aprendizaje Organizacional de Barry University, USA.",
    linkedin: "https://www.linkedin.com/in/erwin-andia-6513a139/",
  },
  {
    image: "/founders/nicolas-gallardo.png",
    name: "Nicolás Gallardo",
    description:
      "Ingeniero Comercial y Magíster en finanzas de la Universidad Adolfo Ibáñez",
    linkedin: "https://www.linkedin.com/in/nicolas-gallardo-a7163426/",
  },
  {
    image: "/founders/cristobal-forno.png",
    name: "Cristóbal Forno",
    description:
      "Ingeniero Comercial, Magíster en Finanzas y Master in Business Law de la Universidad Adolfo Ibáñez",
    linkedin: "https://www.linkedin.com/in/cristobalforno/",
  },
]

const FoundersContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  background: #e5e5e5;
`

const WrapperFounders = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
  color: #000000;
  font-family: "Open Sans";
  padding-bottom: 56px;
  width: 100%;
  @media (min-width: 601px) {
    padding-bottom: 0;
  }
  @media (min-width: 1112px) {
    padding-bottom: 125px;
  }
`

const HeaderFounders = styled.h2`
  font-size: 26px;
  font-weight: bold;
  max-width: 300px;
  line-height: 118.1%;
  text-align: center;
  margin-bottom: 30px;
  @media (min-width: 768px) {
    font-size: 30px;
    max-width: 100%;
  }
`

const FounderContainer = styled.div`
  width: 212px;
  margin: 0 8px;
  scroll-snap-align: start;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 60px;
  @media (max-width: 600px) {
    width: 326px;
    margin: 0;
  }
  @media (min-width: 1112px) {
    font-size: 30px;
    padding: 0;
    margin: 0;
  }
`

const FounderImage = styled.img`
  width: 212px;
`

const FounderName = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  margin-top: 33px;
`

const FounderDescription = styled.div`
  font-size: 14px;
  line-height: 140%;
  width: 212px;
  text-align: center;
`

const slidesCSS = css`
  display: none;
  @media (max-width: 600px) {
    display: flex;
    height: 440px;
  }
`

const dotsCSS = css`
  display: none;
  @media (max-width: 600px) {
    display: flex;
  }
`

const ListFoundersContainer = styled.div`
  display: flex;
  width: 550px;
  flex-wrap: wrap;
  justify-content: space-evenly;
  max-width: 1366px;
  @media (max-width: 600px) {
    display: none;
  }
  @media (min-width: 600px) {
    width: 600px;
  }
  @media (min-width: 1112px) {
    width: 100%;
  }
`

const LinkLinkedin = styled.a`
  margin-top: 15px;
  font-size: 16px;
  text-decoration: none;
  font-weight: 600;
  color: #2977c9;
  &:hover {
    color: #2b5f93;
  }
`

const Founder = ({ data: { image, name, description, linkedin } }: Props) => (
  <FounderContainer>
    <FounderImage src={image} />
    <FounderName>{name}</FounderName>
    <FounderDescription>{description}</FounderDescription>
    {linkedin && (
      <LinkLinkedin href={linkedin} target="_blank" rel="noopener noreferrer">
        LinkedIn
      </LinkLinkedin>
    )}
  </FounderContainer>
)

const Founders = () => {
  return (
    <FoundersContainer>
      <WrapperFounders>
        <HeaderFounders>Nuestros fundadores</HeaderFounders>

        <CustomSlider
          dataSlides={founders}
          slidesCSS={slidesCSS}
          dotsCSS={dotsCSS}
        >
          <Founder data={founders[0]} />
        </CustomSlider>
        <ListFoundersContainer>
          {founders.map((data: FounderType, idx: number) => (
            <Founder key={`idfounder-${idx}`} data={data} />
          ))}
        </ListFoundersContainer>
      </WrapperFounders>
    </FoundersContainer>
  )
}

export default Founders
