import React from "react"
import styled from "@emotion/styled"

import ManifestLogo from "../ui/images/manifest-logo"

const ManifestContainer = styled.div`
  background: #1a1b1c;
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 37px 30px 67px;
  @media (min-width: 736px) {
    padding: 40px 30px 110px;
  }
  @media (min-width: 1024px) {
    padding-top: 50px;
  }
`

const TitleManifest = styled.div`
  font-weight: bold;
  font-size: 23.04px;
  line-height: 115.5%;
  margin-top: 37px;
  margin-bottom: 24px;
  @media (min-width: 736px) {
    margin-top: 28px;
    margin-bottom: 28px;
  }
  @media (min-width: 1024px) {
    margin-top: 17px;
  }
`

const TextManifest = styled.div`
  max-width: 315px;
  > p {
    font-size: 14px;
    line-height: 140%;
    text-align: justify;
    margin-bottom: 2em;
  }
  @media (min-width: 736px) {
    max-width: 525px;
  }
`

const Manifest = () => {
  return (
    <ManifestContainer>
      <div>
        <ManifestLogo />
      </div>
      <TitleManifest>Nuestro manifiesto</TitleManifest>
      <TextManifest>
        <p>
          No existe un lugar en el mundo que cambie con mayor frecuencia y
          velocidad que los mercados financieros. Un lugar donde el instinto de
          evolución nos obliga a actuar rápido, a tomar decisiones improvisadas,
          a aceptar la posibilidad de perder y a reconocer que el cambio es
          parte de nuestra esencia.
        </p>
        <p>
          Esto nos convierte en una especie rara. Nos sentimos vivos en
          ambientes de riesgo y muertos en ambientes seguros. Porque hemos sido
          moldeados por los mercados, moldeados para sobrevivir en entornos
          altamente cambiantes.
        </p>
        <p>
          Somos traders porque somos distintos. Mientras el resto se refugia en
          la seguridad, nosotros buscamos la oportunidad en entornos de riesgo,
          entendiendo que l
          <strong>
            as grandes oportunidades se esconden donde la mayoría no está
            dispuesta a buscar.
          </strong>
        </p>
        <p>
          En este escenario, nos vemos exigidos a desarrollar una actitud
          distinta, con agilidad, con inteligencia y con la capacidad de
          adaptarnos rápidamente a los cambios. La nuestra, es una
          #ActitudTrader.
        </p>
      </TextManifest>
    </ManifestContainer>
  )
}

export default Manifest
