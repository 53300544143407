import React from "react"
import { QueryStringProvider } from "@capitaria/capitaria-utms"
import Layout from "../components/ui/layout"
import SEO from "../components/seo"
import AboutUs from "../components/about-us/about-us"

const AcercaDeNosotros = () => {
  return (
    <QueryStringProvider>
      <Layout>
        <SEO title="CAPITARIA" />
        <AboutUs />
      </Layout>
    </QueryStringProvider>
  )
}

export default AcercaDeNosotros
