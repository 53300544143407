import React from "react"
import styled from "@emotion/styled"

import PreferCapitaria from "../shared/prefer-capitaria"
import DescriptionCapitaria from "../shared/description-capitaria"

import PageLayoutExtend from "../ui/page-layout-extend"
import Header from "./header"
import Video from "./video"
import Manifest from "./manifest"
import Founders from "./founders"
import CTA from "./cta"

const AboutUsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const AboutUs = () => {
  return (
    <PageLayoutExtend header={<Header />}>
      <AboutUsContainer>
        <DescriptionCapitaria />
        <Video />
        <Manifest />
        <Founders />
        <PreferCapitaria />
        <CTA />
      </AboutUsContainer>
    </PageLayoutExtend>
  )
}

export default AboutUs
