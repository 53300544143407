import React from "react"
import styled from "@emotion/styled"
import { useQueryString } from "@capitaria/capitaria-utms"

import { trackEvent } from "../../helpers/tracker"
import Button from "../ui/button"

const Description = styled.div`
  max-width: 300px;
  text-align: center;
  margin-top: 25px;
  font-size: 14px;
  line-height: 140%;
  @media (min-width: 736px) {
    max-width: 420px;
  }
  @media (min-width: 1024px) {
    margin-top: 30px;
    max-width: 575px;
  }
`

const VideoContainer = styled.div`
  padding: 60px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #eeeeee;
  width: 100%;
  @media (min-width: 736px) {
    padding: 55px 15px 70px;
  }
  @media (min-width: 1024px) {
    padding: 105px 15px 165px;
  }
`

const TitleVideo = styled.div`
  font-weight: bold;
  font-size: 23.04px;
  line-height: 115.5%;
  max-width: 330px;
  text-align: center;
  margin-bottom: 36px;
  @media (min-width: 736px) {
    margin-bottom: 45px;
    max-width: 550px;
  }
  @media (min-width: 1024px) {
    max-width: 640px;
  }
`

const VideoWrapper = styled.div`
  min-height: 200px;
  width: 100%;
  max-width: 350px;
  position: relative;
  @media (min-width: 736px) {
    max-width: 560px;
    min-height: 345px;
  }
  @media (min-width: 1024px) {
    max-width: 730px;
    min-height: 410px;
  }
`

const VideoIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const TextCTAVideo = styled.div`
  font-weight: bold;
  font-size: 23.04px;
  line-height: 115.5%;
  margin-top: 65px;
  margin-bottom: 23px;
  @media (min-width: 736px) {
    margin-top: 25px;
  }
  @media (min-width: 1024px) {
    margin-top: 45px;
  }
`

const Video = () => {
  const {
    queryString,
    parsedQueryString: { mc_origen: mcOrigen },
  } = useQueryString()
  return (
    <VideoContainer>
      <TitleVideo>
        Más de 15 años de experiencia comprometidos con llevarte a alcanzar tus
        objetivos
      </TitleVideo>
      <VideoWrapper>
        <VideoIframe
          src="https://player.vimeo.com/video/351486760?title=0&byline=0&portrait=0"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
        ></VideoIframe>
      </VideoWrapper>
      <Description>
        Si vibras con los mercados financieros y estás dispuesto a buscar donde
        otros no se atreven, en Capitaria no solo encontrarás grandes
        oportunidades, sino que además una comunidad que está en tu misma
        sintonía.
      </Description>
      <TextCTAVideo>¡Únete a nuestra comunidad!</TextCTAVideo>
      <Button
        href={`https://onboarding.capitaria.com/sign-up${queryString}`}
        onClick={() => {
          trackEvent("acerca-de-nosotros:video:create-account", {
            mc_origen: mcOrigen,
          })
        }}
      >
        CREA TU CUENTA
      </Button>
    </VideoContainer>
  )
}

export default Video
